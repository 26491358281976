/* eslint-disable camelcase */
/* eslint-disable no-param-reassign */
import React from 'react';
import Logger from '@lmig/dotcom-utilities-helpers/logger';
import getConfig from 'next/config';
import dynamic from 'next/dynamic';
import { titleCase } from 'title-case';
import ComponentMapRender from '@lmig/dotcom-aspect-components/Utility/ComponentMapRender';
import Heading from '@lmig/dotcom-aspect-components/Utility/Heading';
import sluggify from '@lmig/dotcom-aspect-helpers/sluggify';
import Head from "@lmig/dotcom-aspect-comparion-components/Head";
import withPreprocessedContent from "@lmig/dotcom-aspect-comparion-components/Utility/withPreprocessedContent";
import Layout from "@lmig/dotcom-aspect-comparion-components/Layout";
import BreadcrumbSticky from "@lmig/dotcom-aspect-comparion-components/BreadcrumbSticky";
import AgentHero from "@lmig/dotcom-aspect-comparion-components/Agent/AgentHero";
import LazyLoad from "@lmig/dotcom-aspect-comparion-components/LazyLoad";
import Reviews from "@lmig/dotcom-aspect-comparion-components/Reviews";
import sortReviews from "@lmig/dotcom-aspect-comparion-helpers/sortReviews";
import { getServerSideProps as callGetServerSideProps } from "@lmig/dotcom-aspect-comparion-helpers/staticPageController";
import { emsGraphQlQuery } from "@lmig/dotcom-aspect-comparion-helpers/emsGraphQlQuery";
import { filterUndefined } from "@lmig/dotcom-aspect-comparion-helpers/filterUndefined";
import { formatAgent } from "@lmig/dotcom-aspect-comparion-helpers/formatEntity";
import WrapperSizeable from '@lmig/dotcom-aspect-comparion-components/Utility/WrapperSizeable';
import ReviewsSchema from "@lmig/dotcom-aspect-comparion-components/Utility/ReviewsSchema";

const logger = new Logger('client:layout:agent-landing');

const AgentInfo = dynamic(() => import("@lmig/dotcom-aspect-comparion-components/Agent/AgentInfo"));
const AssociateCards = dynamic(() => import("@lmig/dotcom-aspect-comparion-components/Associate/AssociateCards"));
const InsurancePartners = dynamic(() => import("@lmig/dotcom-aspect-comparion-components/InsurancePartners"));
const InsuranceProducts = dynamic(() => import("@lmig/dotcom-aspect-comparion-components/InsuranceProducts"));
const OfficeMap = dynamic(() => import("@lmig/dotcom-aspect-comparion-components/Office/OfficeMap"));
const FooterBanner = dynamic(() => import("@lmig/dotcom-aspect-comparion-components/FooterBanner"));

const {
  publicRuntimeConfig: { basePath, remoteOffices }
} = getConfig();

const AgentLandingPage = withPreprocessedContent(({ content: { mainContent = [], meta = {} }, agent, reviews, address, breadcrumb }) => {
  const componentMap = {
    agentHero: () => <AgentHero agent={agent} />,
    agentInfo: () => <AgentInfo agent={agent} isAgentLanding />,
    associateCards: () => <LazyLoad><AssociateCards associates={agent.associates} state={address.state.name} /></LazyLoad>,
    heading: Heading,
    insurancePartners: ({ ...rest }) => <LazyLoad><InsurancePartners {...rest} /></LazyLoad>,
    insuranceProducts: ({ ...rest }) => <LazyLoad><InsuranceProducts isRemote={agent.isRemote} city={agent.offices[0].address.city} state={agent.offices[0].address.state.code} {...rest} /></LazyLoad>,
    officeMapInformational: () => !agent.isRemote && <LazyLoad><OfficeMap office={agent.offices[0]} /></LazyLoad>,
    reviews: () => <Reviews {...reviews} googleWriteReview={agent.googlePlaceId} comparionWriteReview={agent.firstPartyReviewPage} name={agent?.displayName} />,
    wrapperSizeable: WrapperSizeable,
  };

  return (
    <>
      <Head {...meta} />
      <Layout agentSession={false} capSession={false} agent={agent} clearSession>
        <BreadcrumbSticky backgroundColor="#f5f5f5" breadcrumbList={breadcrumb} />
        <FooterBanner nNumber={agent.nNumber} email={agent.email} phoneNumber={agent.primaryPhone?.number} />
        <div itemProp="mainEntity" itemScope itemType="https://schema.org/InsuranceAgency">
          <meta itemProp="name" content={`${agent.displayName}, Comparion Insurance Agency`} />
          <meta itemProp="brand" content="Comparion Insurance" />
          <meta itemProp="description" content={meta.description} />
          <ReviewsSchema reviews={reviews?.allReviews} totalRating={reviews?.totalRating} />
          <ComponentMapRender content={mainContent} componentMap={componentMap} />
        </div>
      </Layout>
    </>
  );
});

// eslint-disable-next-line complexity
const getServerSideProps = async (context) => {
  const { req: { headers: { agent_alias, office_alias, office_state } } } = context;
  const { query: { officeState = office_state, officeAlias = office_alias, alias = agent_alias }, res } = context;

  const resultProps = await callGetServerSideProps(context);

  if (resultProps.notFound) {
    return resultProps;
  }

  const queryFilter = (officeAlias && officeState) ?
    `type:FIELD,alias:"${alias}",offices:{alias:"${officeAlias}",address:{state:{name:"${titleCase(officeState.replace('-', ' '))}"}}}` :
    `type:FIELD,alias:"${alias}"`;

  const { data: { agentLanding: agent = null } } = await emsGraphQlQuery({
    cacheKeyPrefix: `agentLanding-alias-${alias}`,
    params: {
      query: `query {
        agentLanding:AgentOne(filter:{${queryFilter}}){
          active
          alias
          isRemote
          firstName
          lastName
          preferredName
          displayName
          qualifications{title}
          email
          bio
          aliasPath
          nNumber
          quote
          yextTitle
          title
          services
          products
          education{school year degree fieldOfStudy}
          videoUrl
          webId
          reservationUrl
          social{facebook instagram linkedin twitter}
          image{src alt height width}
          phones{type number extension}
          languages{language}
          awards{title year}
          associates{active firstName preferredName displayName title email phones{type number} image{src alt height width}}
          offices{officeType isRemote alias aliasPath name location{coordinates} 
            address{city street additionalStreetInfo zip state{name code}}}
          localOffice{city street additionalStreetInfo zip state{name code}}
          localOfficeLocation{coordinates}
          rating
          reviews{id status rating content authorName publisherId publisherDate reviewLanguage 
            comments{content visibility authorName authorEmail authorRole publisherDate}}
          googlePlaceId
          firstPartyReviewPage
          dailyHours{
            monday{start end isClosed byAppointmentOnly eveningsByAppointment}
            tuesday{start end isClosed byAppointmentOnly eveningsByAppointment}
            wednesday{start end isClosed byAppointmentOnly eveningsByAppointment}
            thursday{start end isClosed byAppointmentOnly eveningsByAppointment}
            friday{start end isClosed byAppointmentOnly eveningsByAppointment}
            saturday{start end isClosed byAppointmentOnly eveningsByAppointment}
            sunday{start end isClosed byAppointmentOnly eveningsByAppointment}}
          licenseData{
            ak{stateName active licenses{type number}}
            al{stateName active licenses{type number}}
            ar{stateName active licenses{type number}}
            az{stateName active licenses{type number}}
            ca{stateName active licenses{type number}}
            co{stateName active licenses{type number}}
            ct{stateName active licenses{type number}}
            dc{stateName active licenses{type number}}
            de{stateName active licenses{type number}}
            fl{stateName active licenses{type number}}
            ga{stateName active licenses{type number}}
            hi{stateName active licenses{type number}}
            ia{stateName active licenses{type number}}
            id{stateName active licenses{type number}}
            il{stateName active licenses{type number}}
            in{stateName active licenses{type number}}
            ks{stateName active licenses{type number}}
            ky{stateName active licenses{type number}}
            la{stateName active licenses{type number}}
            ma{stateName active licenses{type number}}
            md{stateName active licenses{type number}}
            me{stateName active licenses{type number}}
            mi{stateName active licenses{type number}}
            mn{stateName active licenses{type number}}
            mo{stateName active licenses{type number}}
            ms{stateName active licenses{type number}}
            mt{stateName active licenses{type number}}
            nc{stateName active licenses{type number}}
            nd{stateName active licenses{type number}}
            ne{stateName active licenses{type number}}
            nh{stateName active licenses{type number}}
            nj{stateName active licenses{type number}}
            nm{stateName active licenses{type number}}
            nv{stateName active licenses{type number}}
            ny{stateName active licenses{type number}}
            oh{stateName active licenses{type number}}
            ok{stateName active licenses{type number}}
            or{stateName active licenses{type number}}
            pa{stateName active licenses{type number}}
            pr{stateName active licenses{type number}}
            ri{stateName active licenses{type number}}
            sc{stateName active licenses{type number}}
            sd{stateName active licenses{type number}}
            tn{stateName active licenses{type number}}
            tx{stateName active licenses{type number}}
            ut{stateName active licenses{type number}}
            va{stateName active licenses{type number}}
            vt{stateName active licenses{type number}}
            wa{stateName active licenses{type number}}
            wi{stateName active licenses{type number}}
            wv{stateName active licenses{type number}}
            wy{stateName active licenses{type number}}
          }
        }
      }`
    }
  });

  if (!agent) {
    logger.debug('Agent not found, redirecting to 404 page');
    res.redirect(302, `${basePath}/not-found`);
    res.end();

    return { props: {} };
  }

  const office = agent.offices.find(({ officeType }) => officeType === "FIELD");
  const remoteOffice = agent.offices.find(({ officeType }) => officeType === "REGION");
  const remoteAgentWithLocalOffice = agent.isRemote && agent.localOffice;
  const isRemoteOfficeValid = remoteAgentWithLocalOffice && agent.localOffice.city && agent.localOffice.state.name && agent.localOffice.state.code;
  const officeAddress = isRemoteOfficeValid ? agent.localOffice : office.address;

  if (!office) {
    logger.debug('Agent has no associated office, redirecting to search page');
    res.redirect(302, `${basePath}/insurance-agent`);
    res.end();

    return { props: {} };
  }

  const stateNameWithHyphen = officeState.replace(/%20/g, '-').replace(/ /g, '-');

  if (isRemoteOfficeValid && officeState && remoteOffice?.alias && !remoteOffices.includes(stateNameWithHyphen)) {
    logger.debug(`Agent is remote and has a local office, redirecting to regional agent ${basePath}/${remoteOffice}/${alias}`);
    res.redirect(302, `${basePath}/insurance-agent/${remoteOffice.alias}/${alias}`);
    res.end();

    return { props: {} };
  }

  if (!agent.active) {
    logger.debug('Expired agent, redirecting to office page');

    res.redirect(301, `${basePath}/${office.aliasPath}?notification=expired-agent-search`);
    res.end();

    return { props: {} };
  }

  agent.associates = agent.associates.filter(({ active }) => active);
  const formattedAgent = filterUndefined(formatAgent(agent));
  resultProps.props.agent = formattedAgent;
  resultProps.props.reviews = sortReviews(agent.reviews, agent.rating);
  resultProps.props.address = officeAddress;

  try {
    const currentPageJson = JSON.parse(context.query.currentPage);
    currentPageJson.breadcrumb.push({ url: `/insurance-agent/${sluggify(officeAddress.state.name)}`, name: officeAddress.state.name });

    if (!agent.isRemote) {
      currentPageJson.breadcrumb.push({ url: office.aliasPath, name: `${officeAddress.city}` });
    }

    currentPageJson.breadcrumb.push({ url: agent.aliasPath, name: agent.displayName });
    resultProps.props.breadcrumb = currentPageJson.breadcrumb;
  } catch (e) {
    logger.error(`Error encountered updating breadcrumb with message: ${e.message}`);
  }

  const description = `For personalized insurance quotes in ${officeAddress.city}, ${officeAddress.state.code} contact ${agent.displayName}. Find the right insurance for your car, home & more at an affordable rate.`;

  resultProps.props.content.meta.title = officeAddress.city && officeAddress.state.code ?
    `${agent.displayName} - ${officeAddress.city}, ${officeAddress.state.code} Insurance Agent | Comparion` :
    `${agent.displayName} - Insurance Agent | Comparion`;

  resultProps.props.content.meta.description = description;

  if (!officeAlias && !officeState) {
    const url = `${basePath}/${agent.aliasPath}`;
    logger.debug(`Forwarding to full agent URL: ${url}`);
    res.redirect(302, url);
    res.end();

    return { props: {} };
  }

  resultProps.props.ctxEntity = {
    agent: {
      ...formattedAgent,
      queryValue: formattedAgent.webId,
      queryType: 'WebId',
    },
  };

  return resultProps;
};

export { getServerSideProps };

export default AgentLandingPage;
